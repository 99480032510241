import styled, { device } from "@theme/utils";

export const LatestPostInnerWrap = styled.div`
    margin-top: 40px;
    padding: 30px 24px;
    background: #fafafa;
    border-radius: 10px;
`;
export const LatestPostHeader = styled.div``;
export const LatestPostBox = styled.div``;
